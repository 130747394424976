import AppointmentForm from "../components/appointment-form";
import Page from "../components/page";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import WYSIWYG from "../components/wysiwyg";
import { graphql } from "gatsby";

const TeamDetailPage = ({ data }) => 
  <Page>
    <SEO
      title={`${data.employee.firstName} ${data.employee.lastName} - Team`}
    />
    <div className='container px-8 md:flex mb-12 lg:mb-24 mt-4 lg:mt-16'>
      <div className='md:w-1/3 lg:w-1/4'>
        <img
          className='mb-4 md:mb-6 w-full'
          loading='lazy'
          src={data.employee.profileImage.fluid.src}
          alt={data.employee.firstName}
          title={data.employee.firstName}
          data-aos='fade-right'
        />
        <h2
          className='title break-words'
          data-aos='fade-right'
          data-aos-delay='50'
        >
          <span className='truncate'>{data.employee.firstName}</span>&nbsp;
          <span className='truncate'>{data.employee.lastName}</span>
        </h2>
        <p
          className='font-medium mb-4 md:mb-8 pr-4 text-tertiary'
          data-aos='fade-right'
          data-aos-delay='100'
        >
          {data.employee.jobTitle}
        </p>
        <div
          className='md:mb-1 flex items-center'
          data-aos='fade-right'
          data-aos-delay='150'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            width='16'
            height='16'
            className='flex-shrink-0 mr-3 text-gray-800'
          >
            <path
              className='fill-current'
              d='M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z'
            />
          </svg>
          <a
            className='flex-1 hover:underline'
            href={`mailto:${data.employee.email}`}
          >
            {data.employee.email}
          </a>
        </div>
        {data.employee.phone && 
          <div
            className='md:mb-1 flex items-center'
            data-aos='fade-right'
            data-aos-delay='200'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='16'
              height='16'
              className='mr-3 text-gray-800'
            >
              <path
                className='fill-current'
                d='M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z'
              />
            </svg>
            <a
              className='break-all flex-1 hover:underline'
              href={`tel:${data.employee.phone}`}
            >
              {data.employee.phone}
            </a>
          </div>
        }
      </div>
      <div className='pt-8 md:pt-4 md:pl-8 lg:pl-16 w-full md:w-2/3 lg:w-3/4'>
        <h2 className='title mb-6'>Biografie</h2>
        {data.employee.education && 
          <div className='mb-5 md:mb-5 flex items-start flex-wrap text-gray-800'>
            <svg
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className='mr-2 lg:mr-3 text-tertiary'
            >
              <path
                className='fill-current'
                d='M19 10v6a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2c0-1.1.9-2 2-2v-6a2 2 0 0 1-2-2V7a1 1 0 0 1 .55-.9l8-4a1 1 0 0 1 .9 0l8 4A1 1 0 0 1 21 7v1a2 2 0 0 1-2 2zm-6 0h-2v6h2v-6zm4 0h-2v6h2v-6zm-8 0H7v6h2v-6zM5 7.62V8h14v-.38l-7-3.5-7 3.5zM5 18v2h14v-2H5z'
              />
            </svg>
            <span className='flex-shrink mb-3 md:mb-0 md:w-32'>Opleiding:</span>
            <span
              className='md:flex-1 font-semibold pl-4 max-w-xl'
              data-aos='fade-left'
            >
              {data.employee.education.education}
            </span>
          </div>
        }
        {data.employee.targetAudience && 
          <div className='mb-5 md:mb-5 flex items-start flex-wrap text-gray-800'>
            <svg
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className='mr-2 lg:mr-3 text-tertiary'
            >
              <path
                className='fill-current'
                d='M9 12A5 5 0 1 1 9 2a5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 11a1 1 0 0 1-2 0v-2a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v2a1 1 0 0 1-2 0v-2a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v2zm1-5a1 1 0 0 1 0-2 5 5 0 0 1 5 5v2a1 1 0 0 1-2 0v-2a3 3 0 0 0-3-3zm-2-4a1 1 0 0 1 0-2 3 3 0 0 0 0-6 1 1 0 0 1 0-2 5 5 0 0 1 0 10z'
              />
            </svg>
            <span className='flex-shrink mb-3 md:mb-0 md:w-32'>Doelgroep:</span>
            <span
              className='md:flex-1 font-semibold pl-4 max-w-xl'
              data-aos='fade-left'
              data-aos-delay='50'
            >
              {data.employee.targetAudience.targetAudience}
            </span>
          </div>
        }
        {data.employee.treatments && 
          <div className='mb-5 md:mb-5 flex items-start flex-wrap text-gray-800'>
            <svg
              viewBox='0 0 24 24'
              width='24'
              height='24'
              className='mr-2 lg:mr-3 text-tertiary'
            >
              <path
                className='fill-current'
                d='M4 16v5a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1h8.5a1 1 0 0 1 .7.3l.71.7H21a1 1 0 0 1 .9 1.45L19.11 10l2.77 5.55A1 1 0 0 1 21 17h-8.5a1 1 0 0 1-.7-.3l-.71-.7H4zm7-12H4v10h7.5a1 1 0 0 1 .7.3l.71.7h6.47l-2.27-4.55a1 1 0 0 1 0-.9L19.38 5H13v4a1 1 0 0 1-2 0V4z'
              />
            </svg>
            <span className='flex-shrink mb-3 md:mb-0 md:w-32'>
              Problematiek:
            </span>
            <span
              className='md:flex-1 font-semibold pl-4 max-w-xl'
              data-aos='fade-left'
              data-aos-delay='100'
            >
              {data.employee.treatments.treatments}
            </span>
          </div>
        }
        <div className='py-8' data-aos='fade-up' data-aos-delay='150'>
          <WYSIWYG data={data.employee.biograpgy.json} />
        </div>
        <h2 className='title mb-6' data-aos='fade-up'>
          Maak een afspraak met {data.employee.firstName}
        </h2>
        <div data-aos='fade-up'>
          <AppointmentForm email={data.employee.email} />
        </div>
      </div>
    </div>
  </Page>;


TeamDetailPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default TeamDetailPage;

export const pageQuery = graphql`
  query($id: String) {
    employee: contentfulEmployee(id: { eq: $id }) {
      createdAt
      id
      firstName
      lastName
      email
      phone
      jobTitle
      education {
        education
      }
      targetAudience {
        targetAudience
      }
      treatments {
        treatments
      }
      biograpgy {
        json
      }
      profileImage {
        fluid {
          src
        }
      }
    }
  }
`;
